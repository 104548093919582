import request from '../utils/request';


/**
 * 课时添加学员 根据姓名查询
 * @returns {AxiosPromise}
 */
export function courseSelectStudent(id,name) {
    return request({
        url: '/sys/orders/courseSelectStudent',
        method: 'get',
        params: {id,name}
    })
}
/**
 * 发送短信提醒
 */
export const send = (form) => {
    return request({
        url: '/sys/orders/send',
        method: 'post',
        data: form
    });
};
/**
 * 查看即将到期或者余额不足的学员
 * @return
 */
export function selectExpire(query) {
    return request({
        url: '/sys/orders/selectExpire',
        method: 'post',
        data: query
    })
}


/**
 * 查询近期没有上课的学员
 * @return
 */
export function selectStayUse(query) {
    return request({
        url: '/sys/orders/selectStayUse',
        method: 'get',
        params: query
    })
}

//试课学员列表 
export function getTrialClassApplyPageList(query) {
    return request({
        url: '/sys/crmCustomers/getTrialClassApplyPageList',
        method: 'post',
        data: query
    })
}

export function exportTrialClassApply(query) {
    return request({
        url: '/sys/crmCustomers/exportTrialClassApply',
        method: 'post',
        data: query,
        responseType: 'arraybuffer'
    })
}

export function getPropertyOrdersList(query) {
    return request({
        url: '/sys/sysPropertyOrders/getPropertyOrdersList',
        method: 'post',
        data: query
    })
}
export function getPropertyEquipmentList(pIds) {
    return request({
        url: `/sys/sysPropertyEquipment/getPropertyEquipmentList`,
        method: 'post',
        data: pIds
    })
}