<template>
    <div>
        <div style="margin-bottom: 20px">   
            <el-select v-model="query.fatherIdList" multiple placeholder="请选择种类" style="margin:10px">
                <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
                </el-option>
            </el-select>
            <el-select v-model="query.childrenIdList" multiple placeholder="请选择类目" style="margin:10px">
                <el-option
                v-for="item in propertyList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
                </el-option>
            </el-select>
            <el-input v-model="query.coachName" placeholder="教练姓名" style="width: 160px;margin:10px" class="mr10"></el-input>
            <el-date-picker
                style="width: 260px"
                v-model="v1"
                type="daterange"
                range-separator="至"
                start-placeholder="创建日期"
                end-placeholder="创建日期"
                format="YYYY/MM/DD"
                value-format="YYYY-MM-DD"
            />
            <el-button type="primary" icon="Search" style="margin: 10px" @click="query.pageIndex=1,getDataList()">搜索
            </el-button>
        </div>
        <el-table :data="dataList" border class="table"
                  ref="multipleTable"
                  header-cell-class-name="table-header"
                  :row-style="{height:0+'px'}"
                  :cell-style="{padding:1+'px'}"
                  v-loading="tableLoading"
                  >
            <el-table-column prop="typeName" label="种类"></el-table-column>
            <el-table-column prop="propertyEquipmentName" label="类目"></el-table-column>
            <el-table-column prop="coachName" label="添加教练"></el-table-column>
            <el-table-column prop="money" label="收款金额"></el-table-column>
            <el-table-column prop="typeName" label="收款图片" width="90" align="center">
                <template v-slot="scope">
                <el-image 
                        v-if="scope.row.credentialsImg"
                        class="code"
                        :preview-src-list="JSON.parse(scope.row.credentialsImg)"
                        :src="JSON.parse(scope.row.credentialsImg)">
                  <template #error>
                    <div class="code">
                    </div>
                  </template>
                </el-image>
              </template>
            </el-table-column>
            <el-table-column prop="beizhu" label="备注信息"></el-table-column>
            <el-table-column prop="addTime" label="添加时间"></el-table-column>
        </el-table>
        <div class="pagination">
            <el-pagination
                background
                layout="total, prev, pager, next"
                :current-page="query.pageIndex"
                :page-size="query.pageSize"
                :total="pageTotal"
                @current-change="handlePageChange"
            />
      </div>
    </div>
</template>
<script>
 import {
    getPropertyOrdersList,
    getPropertyEquipmentList} from '@/api/order.js'
 export default{
    data(){ 
        return{ 
            pageTotal:null,
            value2:[],
            query:{
                childrenIdList: [],
                coachName: "",
                fatherIdList: [],
                keyword: "",
                pageIndex: 1,
                pageSize: 10,
                pageSorts: [
                    {
                        asc: true,
                        column: ""
                    }
                ],
                payEndTime: "",
                payStartTime: ""
            },
            v1:[],
            typeList:[],
            propertyList:[],
            dataList:[],
            tableLoading:false
        }
    },
    created(){
        let arr = [0]
        getPropertyEquipmentList(arr).then(res=>{ 
           if(res.code == 200){
            this.typeList = res.data
           }else{ 
            this.$message.error(res.message)
           }
        })
        this.getDataList()
    },
    methods:{
        getDataList(){
            this.tableLoading = true
            if(this.v1 && this.v1.length>0){
                let v1 = this.v1
                this.query.payStartTime = v1[0]
                this.query.payEndTime = v1[1]
            }
            getPropertyOrdersList(this.query).then(res=>{ 
                if(res.data.code == 200){              
                    this.dataList = res.data.data.propertyOrdersList
                    this.pageTotal = res.data.data.allSize
                }else{ 
                    this.$message.error(res.message)
                }
                this.tableLoading = false
            })
            
        },
        handlePageChange(e){
            this.query.pageIndex = e
            this.getDataList()
        }
    },
    watch:{
        'query.fatherIdList':{
            async handler(newValue){
                let arr = newValue
                this.query.childrenIdList = []
                // console.log(typeof(arr));
                getPropertyEquipmentList(arr).then(res=>{ 
                if(res.code == 200){
                    this.propertyList = res.data
                    console.log(this.propertyList);
                }else{ 
                    this.$message.error(res.message)
                }
                })
            },
            deep:true
        }
    }
 }

</script>
<style>

.code {
  margin-top: 6px;
  width: 40px;
  height: 40px;
  border-radius: 6px
}

.el-table__cell {
  position: static !important;
}
</style>
